

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('GTWalsheimPro-Regular.eot');
    src: local('GT Walsheim Pro Regular'), local('GTWalsheimPro-Regular'),
        url('GTWalsheimPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('GTWalsheimPro-Regular.woff2') format('woff2'),
        url('GTWalsheimPro-Regular.woff') format('woff'),
        url('GTWalsheimPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('GTWalsheimPro-Light.eot');
    src: local('GT Walsheim Pro Light'), local('GTWalsheimPro-Light'),
        url('GTWalsheimPro-Light.eot?#iefix') format('embedded-opentype'),
        url('GTWalsheimPro-Light.woff2') format('woff2'),
        url('GTWalsheimPro-Light.woff') format('woff'),
        url('GTWalsheimPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('GTWalsheimPro-Black.eot');
    src: local('GT Walsheim Pro Black'), local('GTWalsheimPro-Black'),
        url('GTWalsheimPro-Black.eot?#iefix') format('embedded-opentype'),
        url('GTWalsheimPro-Black.woff2') format('woff2'),
        url('GTWalsheimPro-Black.woff') format('woff'),
        url('GTWalsheimPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro Condensed';
    src: url('GTWalsheimPro-CondensedBlack.eot');
    src: local('GT Walsheim Pro Condensed Black'), local('GTWalsheimPro-CondensedBlack'),
        url('GTWalsheimPro-CondensedBlack.eot?#iefix') format('embedded-opentype'),
        url('GTWalsheimPro-CondensedBlack.woff2') format('woff2'),
        url('GTWalsheimPro-CondensedBlack.woff') format('woff'),
        url('GTWalsheimPro-CondensedBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('GTWalsheimPro-Thin.eot');
    src: local('GT Walsheim Pro Thin'), local('GTWalsheimPro-Thin'),
        url('GTWalsheimPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('GTWalsheimPro-Thin.woff2') format('woff2'),
        url('GTWalsheimPro-Thin.woff') format('woff'),
        url('GTWalsheimPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('GTWalsheimPro-Thin.eot');
    src: local('GT Walsheim Pro Thin'), local('GTWalsheimPro-Thin'),
        url('GTWalsheimPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('GTWalsheimPro-Thin.woff2') format('woff2'),
        url('GTWalsheimPro-Thin.woff') format('woff'),
        url('GTWalsheimPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

