@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;500&display=swap');
@import url("fonts/font.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'GT Walsheim Pro', 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 300;
  line-height: 23px;
}

h1, h5, dt, a {
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 100;
  line-height: 23px;
}

a {
font-family: 'GT Walsheim Pro', sans-serif;
font-weight: normal;
letter-spacing: 0.5px;
}

h1, h4, dt {
  font-family: 'GT Walsheim Pro', sans-serif;
}

h2, h3 {
  font-family: 'GT Walsheim Pro Condensed', sans-serif;
  font-weight: 900;
  font-style: normal;
}


:root {
    --background: #fff;
    --tech: #F6EEE3;
    --projects: #E8E0D8;
    --skills: #F6CFDC;
    --footer: #F5C703;
  
    --greenHead: #233d2b;
    --fontColor: #141414;
    --fontColormain: #151515;
  
    --codeTag: #141414;
    --codeSkill: #80fb7ab1;
    --codeTool: #c57afb9c;
    --codeMore: #fbc57acb;
    --codeUp: #7afbb8b9;
  
    --icons: #2e2d2d;
    --Hover: #233d2b;
  
  
    --demoBtn: #212a21;
    --codeBtn: #1a381d;
    --defaultBtn: #ffffff;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
